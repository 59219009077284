export class SponsorReceipts {
    id:number;
    sponsorId: number;
    receiptId: number;
    amount: number;
    type: string;
    rdate: string;
    transaction: string;
    receiptType: number;
    months: any;
    parishId: number
}