import { Injectable } from '@angular/core';
import { Enrollment, ViewEnroll } from '../../model/index';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Summary } from '../../model/summary';
import { ViewEnroll2 } from '../../model/viewenrollment2';
import { StudentMaxOut } from '../../model/student.maxout';
import { StudentCount } from '../../model/studentCount';
import { EnrollmentHistory } from '../../model/history';
import { EventPublisher } from '../../model/eventpublisher';

const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
            
@Injectable()
export class EnrollService {

    private apiUrl = 'api';

    constructor(private http: HttpClient) { }

    update(id: number,effectiveDate: string,expirationMonth: number,
        expirationYear: number, contributionAmount: number, miscAmount: number, editedStudents: any) {
        return this.http.post(`${this.apiUrl}/enrollment/update`, {
            id,
            effectiveDate,
            expirationMonth,
            expirationYear,
            contributionAmount,
            miscAmount,
            netAmount: +contributionAmount + +miscAmount,
            sponsees: editedStudents
        }, { headers });
    }

    save(enrollment: Enrollment) {
        return this.http.post<Enrollment>(`${this.apiUrl}/enroll`, JSON.stringify(enrollment), { headers });
    }

    save2(enrollment: Enrollment) {
        return this.http.post<Enrollment>(`${this.apiUrl}/enroll2`, JSON.stringify(enrollment), { headers });
    }

    release(enrollmentId: number) {
        return this.http.post<String>(`${this.apiUrl}/release`, {enrollmentId: enrollmentId}, { headers });
    }

    listEnrollments(parishId: number){
        return this.http.get<Array<ViewEnroll>>(`${this.apiUrl}/view/enrollment/${parishId}`);
    }

    childernsMatrix(parishId: number){
        return this.http.get<Array<StudentCount>>(`${this.apiUrl}/enrollment/childrens/${parishId}`);
    }

    history(enrollmentId: number){
        return this.http.get<EnrollmentHistory>(`${this.apiUrl}/enrollment/history/${enrollmentId}`);
    }


    listcleanups(){
        return this.http.get<Array<ViewEnroll2>>(`${this.apiUrl}/view/cleanup`);
    }

    getSponsoringChilds(sponsorId: number){
        return this.http.get<Array<StudentMaxOut>>(`${this.apiUrl}/enrollment/sponsoringchilds/${sponsorId}`);
    }

    getSummary(parishId: number){
        return this.http.get<Array<Summary>>(`${this.apiUrl}/enrollment/viewsummary/${parishId}`);
    }

    generateReport(enrollmentId: number): Observable<Blob>{     
        return this.http.get(`${this.apiUrl}/enrollment/generatereport/${enrollmentId}`, {
            responseType: "blob"
        });
    }

    publishSponsorshipDocument(enrollmentId: number) {     
        return this.http.post<EventPublisher>(`${this.apiUrl}/enrollment/publish`,  {enrollmentId: enrollmentId}, { headers });
    }

    generateReceipt(enrollmentId: number): Observable<Blob>{     
        return this.http.get(`${this.apiUrl}/enrollment/generatereceipt/${enrollmentId}`, {
            responseType: "blob"
        });
    }

    generateSummary(parishId: number): Observable<Blob>{     
        return this.http.get(`${this.apiUrl}/enrollment/summarypdf/${parishId}`, {
            responseType: "blob"
        });
    }

    generateSummaryExcel(parishId: number): Observable<Blob>{     
        return this.http.get(`${this.apiUrl}/enrollment/summaryexcel/${parishId}`, {
            responseType: "blob"
        });
    }
}
