import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {  Parish, Sponsor } from '../../model';
import { Subject, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { AdminService } from '../../shared/service/admin.service';
import { SponsorService } from '../../shared/service/sponsor.service';
import { SponsorRenewal } from '../../model/sponsorrenewal';
import { RenewalService } from '../../shared/service/renewal.service';
import { DateFormatPipe } from '../../../shared/date.format.pipe';
import { saveAs as importedSaveAs } from "file-saver";
import { PhonePipe } from '../../../shared/phonepipe';

@Component({
  selector: 'app-renewal-reminder',
  templateUrl: './renewal-reminder.component.html',
  styleUrls: ['./renewal-reminder.component.css']
})
export class RenewalReminderComponent implements OnInit {

  @ViewChild('parishSearchBox') parishSearchBox: ElementRef;
  @ViewChild('sponsorSearchBox') sponsorSearchBox: ElementRef;

  private parishSearchTerms = new Subject<string>();

  private sponsorSearchTerms = new Subject<string>();

  parishes: Array<Parish>;

  sponsors: Array<Sponsor>;

  selectedSponsor: any;

  selectedParishId: number;

  selectedParish: Parish;

  sponsorRenewal: SponsorRenewal;

  sponsorRenewals: Array<SponsorRenewal>;

  loading: boolean;

  sponsor: Sponsor;

  constructor(
    private parishService: AdminService<Parish>,
    private sponsorService: SponsorService<Sponsor>,
    private renewalService: RenewalService,
    private datePipe: DateFormatPipe, private phonePipe: PhonePipe) { }

  ngOnInit() {

    this.renewalService.listSponsorRenewals(30).subscribe(
      data => this.sponsorRenewals = data,
      err => console.error('Error in geting sponsor renewals')
    );
    
    this.parishSearchTerms.pipe(   
      debounceTime(300), // wait for 300ms pause in events
      distinctUntilChanged(), // ignore if next search term is same as previous
      switchMap(
        term => {
          return term // switch to new observable each time
            ? // return the http search observable
            this.parishService.search('/api/admin/parishes', term)
            : // or the observable of empty heroes if no search term
            of<Parish[]>([])
        }

      ),
      catchError(error => {
        // TODO: real error handling
        console.log(`Error in component ... ${error}`);
        return of<Parish[]>([]);
      })
    ).subscribe(res => this.parishes = res);

    this.sponsorSearchTerms.pipe(   
      debounceTime(300), // wait for 300ms pause in events
      distinctUntilChanged(), // ignore if next search term is same as previous
      switchMap(
        term => {
          return term // switch to new observable each time
            ? // return the http search observable
            this.sponsorService.searchByParishId(this.selectedParishId, term)
            : // or the observable of empty heroes if no search term
            of<Sponsor[]>([])
        }

      ),
      catchError(error => {
        // TODO: real error handling
        console.log(`Error in component ... ${error}`);
        return of<Sponsor[]>([]);
      })
    ).subscribe(res => this.sponsors = res);

  }

  reset(){
    this.selectedParishId = undefined;
    this.selectedSponsor = undefined
    this.selectedParish = undefined;
    this.parishSearchBox.nativeElement.value = '';
    this.sponsorSearchBox.nativeElement.value = '';
    this.sponsorRenewal = undefined;
    this.ngOnInit();
  }

  searchParish(term: string): void {
    this.parishSearchTerms.next(term);
  }
  searchSponsor(term: string): void {
    this.sponsorSearchTerms.next(term);
  }

  setParish(p: Parish) {
    this.selectedParish = p;
    this.selectedParishId = p.id
    this.parishes = [];
    this.parishSearchBox.nativeElement.value = '';
  }

  setCurrentSponsor(s: Sponsor){
    this.selectedSponsor = s
  }

  getRenewalInfo(id: any){
    //this.selectedSponsor = s;
    this.sponsors = [];
    //this.selectedSponsor = s;
    this.sponsorService.getSponsorRenewalInfo(id).subscribe(
      response => this.sponsorRenewal = response,
      err => console.error(`Error in getting getEnrolledStudentsBySponsorId by ${id}`)
    )
    this.sponsorSearchBox.nativeElement.value = '';
  }

  generateLetter(sponsorRenewal: SponsorRenewal,newNetAmount: string,newEffectiveDate: string,newMaxOut: string){
    sponsorRenewal.id = this.sponsorRenewal.id;
    sponsorRenewal.newNetAmount= newNetAmount;
    sponsorRenewal.newEffectiveDate= this.datePipe.transform(newEffectiveDate, 'toDB');
    sponsorRenewal.newMaxOut= this.datePipe.transform(newMaxOut, 'toDB');
   
    this.renewalService.save(sponsorRenewal).subscribe(
      response => {
        this.sponsorRenewal = response
        if(this.sponsorRenewal)
          this.download( this.sponsorRenewal);
      },
      err => console.error("Error in generating renewal")
    );
  }

  download(sr: SponsorRenewal){
    this.renewalService.download(sr).subscribe(
      blob => {
       // this.loading = true
        importedSaveAs(blob, sr.sponsorCode + ' - Light to Life - Renewal Notice');
      },
      err => console.error(err),
      () => this.loading = false
    );
  }

}
