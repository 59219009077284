import { Component, OnInit } from '@angular/core';
import { Parish } from '../model';
import { AdminService } from '../shared/service/admin.service';
import { EnrollService } from '../shared/service/enroll.service';
import { Summary } from '../model/summary';
import { saveAs as importedSaveAs } from "file-saver";
import { CurrencyPipe } from '@angular/common';
import { Globals } from '../..';
import { CommunicationService } from '../shared/service/communication.service';
import { Communication } from '../model/communication';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { UserService } from '../shared/service/user.service';
import { User } from '../model/user';
import * as moment from 'moment';

class Option{
  key: string;
  value: string;
}

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  summeries: Array<Summary>;
  private message: string;
  private showSummary: boolean = false;
  parishes: Array<Parish>;
  selectedParish: number;
  totalNumberOfStudents: number;
  totalConstribution: number;
  totalSponsor: number;
  loading: boolean;
  messages: Array<Message>;
  communication: Communication;
  users: Array<User>;
  selectedSponsorCode: string;
  selectedSponsorName: string;
  selectedSummary: Summary;
  impact: string;
  impacts: Array<Option>;
  impactOption: Option;
  resolutions: Array<Option>;
  assigneeSelected:boolean = false;

  constructor(private enrollService: EnrollService,
    private adminService: AdminService<Parish>,
    private currencyPipe: CurrencyPipe,
    private globals: Globals,
    private userService: UserService,
    private communicationService: CommunicationService<Communication>) { }


  ngOnInit(): void {

    this.impacts = [{ key:"Info", value:"Info"},
                    { key:"Warn", value:"Warn"},
                    { key:"Block", value:"Block"}]

    this.resolutions = [{ key:"O", value:"Open"},
    { key:"R", value:"Resolved"}]

    this.userService.list().subscribe(
      data => this.users = data,
      err => console.log(err)
    );

    this.adminService.get('/api/admin/parishes')
      .subscribe(
        data => this.parishes = data,
        err => console.log(err)
      );
    this.message = 'Please select a parish to see the summary.'
  }

  selectChange(selector:string, value: any){
    if(selector == 'impact'){
      this.communication.impact = value;
    }else if(selector == 'resolution'){
      this.communication.resolution = value;
    }else if(selector == 'user'){
      if(value != "-1"){
         this.communication.asignedId = value;
         this.assigneeSelected = true;
      }else{
        this.assigneeSelected = false;
      }
    }
  }

  sendMessage(id: number, msg: string) {
    this.communication.moduleName = "EN";
    this.communication.message = {
      createdBy: +sessionStorage.getItem('userId'),
      message: msg,
      communicationId: this.communication.id,
      createdDate: '',
      id: 0,
      firstName: '',
      lastName: ''
    };
    this.communicationService.save(this.communication).subscribe(
      data =>  this.getCommunications('enrollment', id),
      error => console.error(error),
    );
  }
  getCommunication(summary: Summary) {
   // this.selectedSummary = summary;
    this.selectedSponsorCode = summary.sponsorCode;
    this.communication = new Communication()
    this.communication.metadata = ` Sponsor Code:${summary.sponsorCode}, Sponsor Name:${summary.sponsorFirstName} ${summary.sponsorLastName}, Parish Name:${summary.parishName},${summary.parishCity}`;
    this.communication.sponsorId = summary.sponsorId;
    this.communication.enrollmentId = summary.enrollmentId;
    this.getCommunications('enrollment', summary.enrollmentId);
    console.log(" communication - " + JSON.stringify(this.communication));
  }

  getCommunications = (modeule: string, id: number) => {
    this.communicationService.getCommunicationByModule('enrollment', id).subscribe(
      data => {
        if(data)
          this.communication = data;
        this.communication.asignedId = (this.communication.asignedId)? this.communication.asignedId : -1;
        this.assigneeSelected = (this.communication.asignedId != -1) ? true : false;
        this.communication.impact = (this.communication.impact)? this.communication.impact : 'Info';
        this.communication.resolution = (this.communication.resolution)? this.communication.resolution : 'O';
        this.sortMessage();
      },
      error => console.error(error),
    )
  }

  sortMessage() {
    this.communication.messages.sort((m1, m2) => {
      if (m1.id > m2.id) return -1;
      if (m1.id === m2.id) return 0;
      if (m1.id < m2.id) return 1;
    });
  }

  generateSummary() {
    this.enrollService.generateSummary(this.selectedParish).subscribe(
      blob => {
        this.loading = true
        importedSaveAs(blob, `Light to Life Summary - ${this.selectedParish}`.split('.').join('') + ' - Light to Life - Sponsorship Information Document');
      },
      err => console.error(err),
      () => this.loading = false
    );
  }

  onExcelClick(){
    //let fileName="Campaign-"+this.obj.cmpNameToSearch+"_"+this.day + "_" + this.month + "_" + this.year +".xlsx";
  
    this.enrollService.generateSummaryExcel(this.selectedParish).subscribe(
      blob => {
        importedSaveAs(blob, `Light to Life Summary Report- ${this.selectedParish}.xlsx`);
      },
      () =>{
        console.log(' Downloaded. '); 
      }
    )
  }

  onParishSelect(parishId: number) {
    if (parishId != 0) {
      this.selectedParish = parishId;
      this.message = null;
      this.enrollService.getSummary(parishId).subscribe(
        data => {
          this.summeries = data;
          this.summeries.forEach(sponsor =>{
            sponsor.netAmount = sponsor.netAmount - sponsor.miscAmount;
          })
          this.totalNumberOfStudents =
            this.summeries.map(s => s.students.length)
              .reduce((numberOfKids, stCount) => {
                return numberOfKids + stCount;
              }, 0);
          this.totalConstribution = this.summeries.map(s => s.netAmount)
            .reduce((ctn, netAmount) => {
              return ctn + netAmount;
            }, 0);
          if (this.summeries.length < 1) {
            this.showSummary = false;
            this.message = 'There is no summary for the selected parish.';
          } else {
            this.message = `Total number of Sponsor's: ${this.summeries.length}, Total number of students: ${this.totalNumberOfStudents}, net fund untilized is: ${this.currencyPipe.transform(this.totalConstribution, 'USD')}`;
            this.showSummary = true;
          }
        },
        err => console.log(err));
    } else {
      this.message = 'Please select a parish to see the summary.'
      this.showSummary = false;
    }
  }
}