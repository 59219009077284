import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Renewal } from "../../model/renewal";
import { Observable } from "rxjs/Observable";
import { SponsorRenewal } from "../../model/sponsorrenewal";

const headers = new HttpHeaders()
            .set('Content-Type', 'application/json');
@Injectable()
export class RenewalService {

    private api = 'api/renewal';  // URL to web api

    constructor(private httpClient: HttpClient) { }

    generateLetter(receiptId: number){
        return this.httpClient.get(this.api + "/generaterenewal/" + receiptId, {
            responseType: "blob"
        });
    }

    list(): Observable<Array<Renewal>> {
        return this.httpClient.get<Array<Renewal>>(`${this.api}/list`)
    }

    listSponsorRenewals(range: number): Observable<Array<SponsorRenewal>> {
        return this.httpClient.get<Array<SponsorRenewal>>(`${this.api}/list/range/${range}`)
    }


    save(sr: SponsorRenewal) {
        return this.httpClient.post<SponsorRenewal>(`${this.api}/add`, JSON.stringify(sr), { headers });
    }

    download(sr:SponsorRenewal): Observable<Blob>{     
        return this.httpClient.get(`${this.api}/generaterenewal/${sr.id}`, {
            responseType: "blob"
        });
    }
}