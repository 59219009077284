import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../../globals';
import { Communication } from '../../model/communication';
import { Message } from '../../model/message';
import { Summary } from '../../model/summary';
import { User } from '../../model/user';
import { CommunicationService } from '../../shared/service/communication.service';
import { UserService } from '../../shared/service/user.service';


class Option{
  key: string;
  value: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  userId:any;
   
  @Input()
  communications: Array<Communication>;
  @Input()
  user: string;

  messages: Array<Message>;
  communication: Communication;
  users: Array<User>;
  selectedSponsorCode: string;
  selectedSponsorName: string;
  selectedSummary: Summary;
  impact: string;
  impacts: Array<Option>;
  impactOption: Option;
  resolutions: Array<Option>;
  assigneeSelected:boolean = false;
  showResponseAttributes: boolean;
  selectedCommunicationId: number;
  //selectedCommunication: Communication;
  
  constructor(private router: Router, 
    private globals: Globals, 
    private userService: UserService,
    private communicationService: CommunicationService<Communication>) { }

  ngOnInit() {

    this.impacts = [{ key:"Info", value:"Info"},
                    { key:"Warn", value:"Warn"},
                    { key:"Block", value:"Block"}]

    this.resolutions = [{ key:"O", value:"Open"},
    { key:"R", value:"Resolved"}]

    this.userService.list().subscribe(
      data => this.users = data,
      err => console.log(err)
    );

   this.userId = window.sessionStorage.getItem('userId');
   if(!this.userId){
     this.router.navigate(['/login'], { skipLocationChange: true });
   }
  }

  selectChange(selector:string, value: any){
    if(selector == 'impact'){
      this.communication.impact = value;
    }else if(selector == 'resolution'){
      this.communication.resolution = value;
    }else if(selector == 'user'){
      if(value != "-1"){
         this.communication.asignedId = value;
         this.assigneeSelected = true;
      }else{
        this.assigneeSelected = false;
      }
    }
  }

  setCurrentCommunication(communicationId: number){
   // this.selectedCommunicationId =  communicationId;
    this.showResponseAttributes = false;
  }

  showResponeAttributes(communication: Communication, messageId: number){
    this.showResponseAttributes = true;
    this.selectedCommunicationId =  communication.id;
    this.communication = communication;
  }

  cancelRespone(){
    this.showResponseAttributes = false;
    this.selectedCommunicationId =  undefined;
    this.communication = undefined;
  }

  sendMessage(id: number, msg: string) {
    this.communication.moduleName = "EN";
    this.communication.message = {
      createdBy: +sessionStorage.getItem('userId'),
      message: msg,
      communicationId: this.communication.id,
      createdDate: '',
      id: 0,
      firstName: '',
      lastName: ''
    };
    this.communicationService.save(this.communication).subscribe(
      data => { 
        console.log('Message Send');
        this.cancelRespone()
      }, // this.getCommunications('enrollment', id),
      error => console.error(error),
    );
  }

  sortMessage() {
    this.communication.messages.sort((m1, m2) => {
      if (m1.id > m2.id) return -1;
      if (m1.id === m2.id) return 0;
      if (m1.id < m2.id) return 1;
    });
  }

}
