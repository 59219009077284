import { Injectable } from '@angular/core';
import { LoginService } from './login/service/login.service';

@Injectable()
export class Globals {
    ROLES: any;

    constructor(public loginservice: LoginService){
        loginservice.subject.subscribe(value => {
            this.ROLES = value
            console.log("Got Subscription of User roles"); // Subscription wont get 
                                                   // anything at this point
          });
    }
    
    AUTHORITY_CONSTANTS = {
        ADD_AGENCY: 'ADD_AGENCY',
        EDIT_AGENCY: 'EDIT_AGENCY',
        ADD_PROJECT: 'ADD_PROJECT',
        EDIT_PROJECT: 'EDIT_PROJECT',
        ADD_PARISH: 'ADD_PARISH',
        EDIT_PARISH: 'EDIT_PARISH',
        MAP_PROJECT: 'MAP_PROJECT',
        ADD_ORG: 'ADD_ORG',
        EDIT_ORG: 'EDIT_ORG',
        ADD_RECEIPT: 'ADD_RECEIPT',
        LINK_RECEIPT: 'LINK_RECEIPT',
        DOWNLOAD_RECEIPT: 'DOWNLOAD_RECEIPT',
        ADD_SPONSOR: 'ADD_SPONSOR',
        EDIT_SPONSOR: 'EDIT_SPONSOR',
        ADD_STUDENT: 'ADD_STUDENT',
        EDIT_STUDENT: 'EDIT_STUDENT',
        CAN_ENROLL: 'CAN_ENROLL',
        EDIT_ENROLLMENT: 'EDIT_ENROLLMENT',
        DOWNLOAD_SPON_DOC: 'DOWNLOAD_SPON_DOC',
        DOWNLOAD_SPON_SUMMARY: 'DOWNLOAD_SPON_SUMMARY',
        SUB_STUDENT: 'SUB_STUDENT',
        RELEASE_EXP_SPON: 'RELEASE_EXP_SPON',
        BACKLOG_CLEANUP: 'BACKLOG_CLEANUP',
        DOWNLOAD_RENEWAL_DOC: 'DOWNLOAD_RENEWAL_DOC',
        DOWNLOAD_RENEWAL_REMINDER: 'DOWNLOAD_RENEWAL_REMINDER',
        UPLOAD_SPON_DOC: 'UPLOAD_SPON_DOC',
        UPLOAD_STUDENT_DOC: 'UPLOAD_STUDENT_DOC',
        ADD_PARISH_BREAKDOWN: 'ADD_PARISH_BREAKDOWN',
        GLOBAL_UNLINK: 'GLOBAL_UNLINK',
        UNLINK_RECEIPT: 'UNLINK_RECEIPT',
        PUBLISH_SPON_DOC: 'PUBLISH_SPON_DOC',
        DOWNLOAD_SPON_EXL: 'DOWNLOAD_SPON_EXL'

    }

    checkPermission = (name: string) => {
        if (this.ROLES && this.ROLES.length > 0) {
            if (name === 'AddAgency') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_AGENCY) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddProject') {
                console.log('Add Project', this.ROLES)
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_PROJECT) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddParish') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_PARISH) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddOrganization') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_ORG) > -1) {
                    return true
                } else false;
            }
            if (name === 'MapProject') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.MAP_PROJECT) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddReceipt') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_RECEIPT) > -1) {
                    return true
                } else false;
            }
            if (name === 'LinkSponsor') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.LINK_RECEIPT) > -1) {
                    return true
                } else false;
            }
            if (name === 'DownloadReceipt') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_RECEIPT) > -1) {
                    return true
                } else false;
            }
            if (name === 'EnterBreakDown') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_PARISH_BREAKDOWN) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddSponsor') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_SPONSOR) > -1) {
                    return true
                } else false;
            }
            if (name === 'AddStudent') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.ADD_STUDENT) > -1) {
                    return true
                } else false;
            }
            if (name === 'EditEnrollment') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.EDIT_ENROLLMENT) > -1) {
                    return true
                } else false;
            }
            if (name === 'ManageEnrollment') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.CAN_ENROLL) > -1) {
                    return true
                } else false;
            }
            if (name === 'DownloadEnrollmentDoc') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_SPON_DOC) > -1) {
                    return true
                } else false;
            }
            if (name === 'DownloadEnrollmentSummaryDoc') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_SPON_SUMMARY) > -1) {
                    return true
                } else false;
            }
            if (name === 'SubstitueStudent') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.SUB_STUDENT) > -1) {
                    return true
                } else false;
            }
            if (name === 'ReleaseSponsorShip') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.RELEASE_EXP_SPON) > -1) {
                    return true
                } else false;
            }

            if (name === 'BacklogClean') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.BACKLOG_CLEANUP) > -1) {
                    return true
                } else false;
            }
            if (name === 'DownloadRenewalDoc') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_RENEWAL_DOC) > -1) {
                    return true
                } else false;
            }
            if (name === 'DownloadRenewlReminder') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_RENEWAL_REMINDER) > -1) {
                    return true
                } else false;
            }
            if (name === 'UploadSponsorDoc') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.UPLOAD_SPON_DOC) > -1) {
                    return true
                } else false;
            }
            if (name === 'UploadStudentDoc') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.UPLOAD_STUDENT_DOC) > -1) {
                    return true
                } else false;
            }
            if (name === 'GlobalUnlink') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.GLOBAL_UNLINK) > -1) {
                    return true
                } else false;
            }
            if (name === 'UnlinkReceipts') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.UNLINK_RECEIPT) > -1) {
                    return true
                } else false;
            }
            if (name === 'PublishSponsorshipDocument') {
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.PUBLISH_SPON_DOC) > -1) {
                    return true
                } else false;
            }
            if(name == 'DownloadSponsorExcel'){
                if (this.ROLES.indexOf(this.AUTHORITY_CONSTANTS.DOWNLOAD_SPON_EXL) > -1) {
                    return true
                } else false;
            }
            
        }
    }
}